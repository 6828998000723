.moreinfo_block{
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include for-tablet-portrait-up {
        margin-top: 4rem;
        margin-bottom: 3rem;
    }
    @include for-desktop-up {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    p{
        text-align: justify;
    }
}