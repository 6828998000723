.cta-one-block{
    background-color:$primary;
    position: relative;
    padding:2.5rem 1rem;
    &__content{
        margin-bottom: 1.5rem;
        &__heading{
            text-align: center;
            color:white;
            font-size: 2rem;
            span{
                color:black;
            }
        }
        &__text{
            text-align: center;
            color:white;
        }
        &__button{
            width: 100%;
        }
    }
    &__content-social{
        .fa-envelope{
            font-size: 2rem;
            display: block;
            text-align: center;
            margin-bottom: 1rem;
        }
        a{
            display: block;
            color:white;
            text-align: center;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
        &__icons{
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap:1rem;
            a{
                color:white;
                padding:0.6rem 1rem;
                border:1px solid white;
                &:hover{
                    background-color:white;
                }
                &.facebook{
                    &:hover{
                        i:before{
                            color:#4267B2;
                        }
                    }
                }
                &.linkedin{
                    &:hover{
                        i:before{
                            color:#0077B5;
                        }
                    }
                }
            }
        }
    }
    &__badge{
        display: none;
        width:140px;
        height:140px;
        position: absolute;
        left:auto;
        right:30%;
        bottom:-35%;
        top:auto;
        z-index: 1;
        background:{
            repeat:no-repeat;
            size:contain;
        }
    }
    @include for-tablet-portrait-up {
        padding:5rem 0rem;
        .container{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        &__content{
            margin-bottom: 0;
            &__heading{
                text-align: left;
            }
            &__text{
                text-align: left;
                width:60%;
                font-size: 0.9rem;
            }
            &__button{
                width: auto;
            }
        }
        &__content-social{
            .fa-envelope{
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }
            &__icons{
                gap:0.5rem;
                a{
                    padding:0.4rem 0.8rem;
                }
            }
        }
        &__badge{
            display: block;
            width:160px;
            height:160px;
            position: absolute;
            left:auto;
            right:20%;
            bottom:-20%;
            top:auto;
            z-index: 0;
            background:{
                repeat:no-repeat;
                size:contain;
            }
        } 
    }
    @include for-tablet-landscape-up {
        padding:5rem 0rem;
        .container{
        }
        &__content{
            margin-bottom: 0;
            &__heading{
                font-size: 2.5rem;
            }
            &__text{
                width:80%;
                font-size: 1rem;
            }
            &__button{
            }
        }
        &__content-social{
            padding-top: 0.5rem;
            .fa-envelope{
                font-size: 2rem;
                margin-bottom: 0.5rem;
            }
            &__icons{
                gap:1rem;
                a{
                    padding:0.6rem 1rem;
                }
            }
        }
        &__badge{
            
        } 
    }
    @include for-desktop-up {
        padding:7rem 0rem;
        &__badge{
            width:200px;
            height:200px;
            right:25%;
            bottom:-20%;
        } 
    }
}