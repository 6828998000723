@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body{
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    font-size: 15px;
    font-weight: 400;
    background: white;
    color: #000;
}

h1,
h2,
h3,
h4{
    font-weight: 300;
}
h5, h6{
    font-weight: 400;
}

.primary-color{
    color: $primary;
}

.white-color{
    color:white !important;
}