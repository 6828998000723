.event-block{
    padding: 0;
    margin: 0;

    .row{
        --bs-gutter-x: 0;
    }

    &__article{
        position: relative;
        opacity: 1;
        transform: translate(0, 0) scale(1);
        overflow: hidden;

        &__image{
            position: relative;
            background: none top center / cover no-repeat #eee;
            overflow: hidden;
            display: block;
            height: 250px;
            min-height: 250px;
            padding-top: 60%;
            padding-bottom: 50px;
        }
        
        @include for-tablet-portrait-up {
            &:not(:hover){
                .event-block__article__text{
                    bottom: -145px;
                }
            }
        }

        &__text{
            background: linear-gradient(to bottom, transparent 0%, black 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
            color: #fff;
            padding: 0 30px 16px 30px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transition: bottom 0.5s ease;
            &__title{
                margin: 0;
                display: block;
                h2{
                    a{
                        color:white;
                        text-decoration: none;
                    }
                }
            }
            &__content{
                height: 145px;
                padding-top: 16px;
            }
        }
    }
}