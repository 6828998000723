.main-header{
    padding:1rem 0.5rem;
    @include for-tablet-portrait-up {
        padding:2rem 0 0;
    }
}

.logo-wrapper{
    width: auto;
    flex: 0 1 auto;
}

.logo{
    @include for-phone-only {
        width:120px;
    }
    @include for-tablet-portrait-up {
        margin-top: -1rem;
    }
}

.top-bar{
    display: flex;
    align-items: center;
    align-content: center;
    gap:2rem;
}

.social-icon{
    i{
        color:#BBBBBB;
    }
    &.facebook{
        &:hover{
            i:before{
                color:#4267B2;
            }
        }
    }
    &.linkedin{
        &:hover{
            i:before{
                color:#0077B5;
            }
        }
    }
}

.localisation{
    img{
        width:30px;
    }
}

.search-bar{
    padding: 2px 5px;
    background: #eee;
    border-radius: 5px;
    max-width: 300px;
    input{
        border: none;
        background: none;
        box-shadow: none;
        color: #999;
        width: 150px;
        font-style: italic;
        &:focus {
            color: #999;
            background-color: transparent;
            border-color: transparent;
            outline: 0;
            box-shadow: none;
        }
    }
    .input-group-btn{
        position: relative;
        font-size: 0;
        white-space: nowrap;
        button{
            min-width: 0;
            padding:10px;
            border: none;
            background: none;
            color: #eee;
            &:after{
                content: "\f002";
                font-family: FontAwesome;
                color: #bbb;
            }
        }
    }
}

.main-navigation{
    display: flex;
    justify-content:end;
    .dropdown-trigger{
        display: none;
    }
    ul{
        padding: 0;
        margin: 0;
        list-style-type: none;
        li{
            display: inline-block;
            padding: 0 1rem;
            a{
                position: relative;
                padding-top: 25px;
                padding-bottom: 35px;
                font-size: 15px;
                font-weight: 600;
                display: inline-block;
                &:after{
                    content: '';
                    display: block;
                    height: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: transparent;
                }
            }
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
            }
            &.active,
            &:hover {
                a{
                    &:after{
                        background-color: $primary;
                    }
                }
            }
            &.dropdown{
                &:first-child(){
                    .dropdown-menu{
                        left:0;
                        right:auto;
                    }
                }
                &:last-child(){
                    .dropdown-menu{
                        right:0;
                        left:auto;
                    }
                }
                .dropdown-menu{
                    border-radius: 0;
                    border:none;
                    right: 0;
                    left:0;
                    li{
                        display: block;
                        padding-left:0;
                        padding-right: 0;
                        a{
                            padding:0.3rem 0.8rem;
                            display:block;
                        }
                    }
                }
                &:hover{
                    .dropdown-menu{
                        li{
                            a:after{
                                display: none;
                            }
                        }
                    }
                }
            }

        }
    }
}

.desktop-menu{
    display: none;
    @include for-tablet-portrait-up {
        display: block;
    }
}

.mobile-nav-trigger{
    display: block;
    button{
        font-size: 1.5rem;
        float:right;
    }
    @include for-tablet-portrait-up {
        display: none;
    }
}

.offcanvas{
    .offcanvas-header{
        padding-bottom: 0;
    }
    .offcanvas-body{
        .mobile-nav-title{
            color: rgba(0,0,0,.3);
            text-align:center;
        }
        .navbar-nav{
            a{
                font-size: 0.9rem;
                color:$gray;
            }
            .nav-item{
                border-bottom:1px solid $lightGray;
                &:first-child{
                    border-top:1px solid $lightGray;
                }
                &:hover,
                &.active{
                    border-bottom:1px solid $primary;
                    .nav-link{
                        color:$primary;
                    }
                }
            }
            .nav-item.dropdown{
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: space-between;
                flex-wrap: wrap;
                .nav-link{
                    
                }
                .dropdown-trigger{
                    background: none;
                    border: none;
                    padding: 0;
                    width:30px;
                    i{
                        transform: rotate(0);
                        transition-duration:0.3s;
                        color:rgba($gray, 0.6);
                    }
                    &.show{
                        i{
                            transition-duration:0.3s;
                            transform: rotate(3.142rad);
                        }
                    }
                }
                .dropdown-menu{
                    &.show{
                        position: static !important;
                        inset: auto !important;
                        margin: 0px !important;
                        transform: none !important;
                        display: block;
                        width: 100%;
                        border: none;
                        padding: 0;
                        .dropdown-item{
                            padding-left: 1rem;
                            padding-right: 0;
                            
                        }
                    }
                }
            }
        }
    }
    .offcanvas-footer{
        border-top:1px solid $lightGray;
    }
}