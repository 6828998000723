.hero-block{
    position: relative;
    background:{
        repeat:no-repeat;
        size:cover;
        position:center center;
    }
    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 0 1000px rgba(0,0,0,.5);

    height: calc( 100vh - 100px);
    min-height: 340px;
    max-height: 600px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    &__content-wrapper{
        
    }
    &__heading{
        color:white;
        width:100%;
        display: block;
        min-height: 7rem;
        font-size: 2.5rem;
        .anim-typewriter{
            color:$primary;
            display: block;
        }
    }
    &__text{
        color:white;
        width: 50%;
        font-size: 1.2rem;
        display: none;
    }
    &__button-wrapper{
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap:1rem;
        margin-top: 2rem;
    }
    &__button-one{
        width: 100%;
    }
    &__button-two{
        width: 100%;
    }
    
    @include for-tablet-portrait-up{
        height: calc( 100vh - 350px);
        min-height: 390px;
        max-height: 1200px;
        &__text{
            display: block;
        }
        &__heading{
            min-height: auto;
            .anim-typewriter{
                display: inline-block;
            }
        }
        &__text{
            width: 70%;
        }
        &__button-wrapper{
            justify-content: flex-start;
        }
        &__button-one{
            width: auto;
        }
        &__button-two{
            width: auto;
        }
    }
    @include for-tablet-landscape-up{
        height: calc( 100vh - 350px);
        &__heading{
            .anim-typewriter{
            }
        }
        &__text{
            width: 50%;
        }
    }
}