.packagedetail-block {
    background-color: $primary;
    padding-top: 4rem;
    padding-bottom: 4rem;
    &__wrapper{
        
        &__content{
            .top-content{
                text-align: center;
                .icon{
                    display: inline-block;
                    margin-bottom: 0.5rem;
                }
                h4{
                    color:white;
                }
                .date{
                    display: inline-block;
                    color:white;
                }
            }
            .content{
                border-top:1px solid rgba(white, 0.5);
                border-bottom:1px solid rgba(white, 0.5);
                padding:2.5rem 0;
                margin-top:2.5rem;
                margin-bottom:2.5rem;
                color:white;
                ul{
                    margin: 0;
                    padding-left: 1.5rem;
                    li{
                        list-style:none;
                        position: relative;
                        padding-bottom: 0.5rem;
                        &:last-child{
                            padding-bottom: 0;
                        }
                        &:before {
                            content: "\f058";
                            display: inline-block;
                            margin-right: 1.5rem;
                            font-family: FontAwesome;
                            position: absolute;
                            top: 0;
                            left: -1.5rem;
                        }
                    }
                }
                p{
                    color:white;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
            }
            .bottom-content{
                display: flex;
                align-items: center;
                justify-content: center;
                gap:1rem;
                flex-wrap: wrap;
                .btn{
                    flex:0 1 auto;
                    &.disabled{
                        color:white;
                        border-color:white;
                        span{
                            border-color:white;
                            color:white;
                        }
                        &:hover{
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
}