.footer{
    position: relative;
    padding:1rem 0;
    z-index: 1;
    font-size: 70%;
    p{
        text-align: center;
    }
    a{
        text-align: center;
        display: inline-block;
        text-decoration: none;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: block;
        text-align: center;
        li{
            display: inline-block;
            &:after{
                content: '|';
                margin:0 0.25rem;
                display: inline-block;
                color:black;
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
        }
    }
    .creator{
        margin-bottom: 0;
        margin-top: 0.5rem;
    }

    @include for-tablet-portrait-up {
        padding:1.5rem 0;
        .container{
            display: flex;
            align-items:flex-end;
            justify-content: space-between;
        }
        p{
            text-align: left;
            width:70%;
        }
        a{
            text-align: left;
        }
        ul{
            text-align: left;
        }
        .flex-item:last-child{
            flex:0 0 auto;
        }  
        .creator{
            width: 100%;
        }
    }
    @include for-tablet-landscape-up {
        font-size: 80%;
    }
}