.team-members{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap:2rem;
    .team-member{
        text-align: center;
        .circle-image{
            width:100px;
            height:100px;
            border-radius:50%;
            margin:0 auto;
            background:{
                repeat:no-repeat;
                size:cover;
                position:center;
            }
        }
        h5{
            margin-top: 1rem;
        }
    }
}