.faq-block{
    margin-bottom: 3rem;
    h4{
        margin-bottom: 2rem;
    }
    &__accordion{
        &__item{
            border:1px solid $primary !important;
            &__header{
                // background-color:$primary;
                // color:white;
                border-bottom:2px solid white;
                .accordion-button{
                    &:focus{
                        box-shadow:none;
                    }
                    background-color:$primary;
                    p{
                        color:white;
                        font-weight: bold;
                    }
                }
                p{
                    margin: 0;
                }
            }
            &__content{
                border-width:1px;
                border-left:1px solid $primary;
                border-right:1px solid $primary;
            }
        }
        &__item:last-child{
            // border-bottom:1px solid $primary !important;
            .faq-block__accordion__item__header{
                border-bottom:none !important;
            }
        }
    }
}