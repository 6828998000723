.agenda-block{
    padding: 0;
    margin: 0;

    .flex-wrapper{
        @include for-phone-only {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;  
        }
        .filter-button{
            padding:0.5rem 0.75rem;
            border:1px solid $primary;
            border-radius: 5px;
            .fa-filter{
                color:$primary;
            }
            &:hover{
                cursor: pointer;
                background-color:$primary;
                .fa-filter{
                    color:white;
                }
            } 
            &.active{
                background-color:$primary;
                .fa-filter{
                    color:white;
                }
            }
            @include for-tablet-portrait-up {
                display: none;
            }
        }
    }

    .filters{
        ul{
            justify-content: center;
            list-style: none;
            padding-left: 0;
            align-items: center;
            justify-content:flex-start;
            flex-wrap: wrap;
            gap:12px;
            margin: 25px 0;
            
            li{
                font-weight: 500;
                font-size: 16px; 
                position: relative;
                cursor: pointer;
                display: inline-block;
                border-bottom: 4px solid transparent;
                a{
                    text-align: center;
                    padding: 0 5px 8px 5px;
                    color: $gray;
                    text-transform: uppercase;
                    border-bottom: 4px solid transparent;
                    text-decoration: none;
                    &:hover{
                        color: $primary;
                        border-bottom: 4px solid $primary;
                    }
                }
                &.is-checked{
                    a{
                        border-bottom: 4px solid $primary;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                &.primary{
                    color:$primary;
                    a{
                        color:$primary;
                    }
                    &:hover{
                        color: $primary;
                        // border-bottom: 4px solid $primary;
                    }
                }
            }

            @include for-phone-only {
                display: none;
                background-color: #f2f2f2;
                padding: 0.5rem;
                &.collapsed{
                    display: flex;
                }
            }

            @include for-tablet-portrait-up {
                display: flex;
            }
        }
    }

    .grid{
        --bs-gutter-x: 15px;
    }

    &__article{

        margin-bottom: 15px;
        
        @include for-tablet-portrait-up {
            &:not(:hover){
                .agenda-block__article__wrapper__text{
                    bottom: -105px;
                }
            }
        }

        &__wrapper{
            position: relative;
            opacity: 1;
            transform: translate(0, 0) scale(1);
            overflow: hidden;

            &__image{
                position: relative;
                background: none top center / cover no-repeat #eee;
                overflow: hidden;
                display: block;
                height: 250px;
                min-height: 250px;
                padding-top: 60%;
                padding-bottom: 50px;
            }
            
    
            &__text{
                background: linear-gradient(to bottom, transparent 0%, black 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
                color: #fff;
                padding: 0 30px 16px 30px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                transition: bottom 0.5s ease;
                &__title{
                    margin: 0;
                    display: block;
                    h2{
                        a{
                            color:white;
                            text-decoration: none;
                        }
                    }
                }
                &__content{
                    height: 105px;
                    padding-top: 16px;
                }
            }
        }
        &__additional-block{
            display: flex;
            align-items: center;
            height: 110px;
            overflow: hidden;
            padding: 30px 30px;
            color: #fff;
            background-color: $primary;
        }

    }
}