

.btn{
    border-radius:0;
}

.btn{
    min-width: 180px;
    @include for-tablet-portrait-up {
        min-width: 240px;
    }
    padding: 10px 0px;
}

.btn-primary{
    color:white;
    font-weight: bold;
    &:hover,
    &:focus{
        color:black;
        background-color:white;
        border-color:white;
    }
    &-inverted{
        color:black;
        font-weight: bold;
        background-color:white;
        border-color:white;
        &:hover,
        &:focus{
            color:white;
            background-color:transparent;
            border-color:white;
            .price-link{
                color:white;
            }
        }
        .price-link{
            color:$primary;
            display: inline-block;
            font-style: normal;
            margin-left: 0.25rem;
        }
    }
    &-submit{
        color:white;
        font-weight: bold;
        background-color:$primary;
        border-color:$primary;
        &:hover,
        &:focus{
            color:white;
            background-color:darken($primary, 10)
        }
    }
}

.btn-secondary-outline{
    border-color:white;
    color:white;
    font-weight: bold;
    &:hover,
    &:focus{
        color:black;
        background-color:white;
        border-color:white;
    }
}

.btn-submit{
    color:white;
    font-weight: bold;
    &:hover,
    &:focus{
        color:black;
        background-color:white;
        border-color:white;
    }
}