.pageheader-block{
    position: relative;
    background:{
        repeat:no-repeat;
        size:cover;
        position:center center;
    }
    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 0 1000px rgba(0,0,0,.7);

    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 3rem;
    
    &__heading{
        width:100%;
        display: block;
        font-size: 2.5rem;
        color: $primary;
    }
    &__breadcrumb{
        background-color: rgba(white, 0.9);
        border-radius: 5px;
        padding:0.4rem 0.5rem;
        ul{
            margin: 0;
            padding: 0;
            display: block;
            list-style-type: none;
            li{
                display: inline-block;
                &:after{
                    content: "/";
                    display: inline-block;
                    color: #777;
                    margin-left: 0.3rem;
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                &.active{
                    a{
                        color:$primary;
                        cursor:default;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
                a{
                    color: #777;
                    text-decoration: none;
                    font-size: 0.8rem;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    
    @include for-tablet-portrait-up{
        height:220px;
        &__heading{
            margin-bottom: 1rem;
        }
        &__breadcrumb{
            background-color: transparent;
            padding:0;
            ul{
                li{
                    &:after{
                        color: white;
                    }
                    a{
                        color:white;
                        // font-size: 0.8rem;
                    }
                }
            }
        }
    }
    
    @include for-desktop-up {
        margin-bottom: 3rem;
    }
}