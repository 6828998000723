.imagecarousel-block-carousel{
    margin-top: 2rem;
    &__cell{
        display: block;
        img{
            display: block;
            height: 200px;      
        }
    }
    @include for-tablet-portrait-up {
        &__cell{
            img{
                height: 400px;
            }
        }
    }
    @include for-tablet-landscape-up {
        &__cell{
            img{
                height: 300px;
            }
        }
    }
    @include for-desktop-up {
        &__cell{
            img{
                height: 280px;
            }
        }
    }
    @include for-big-desktop-up {
        &__cell{
            img{
                height: 400px;
            }
        }
    }
    @include for-bigger-desktop-up {
        &__cell{
            img{
                height: 600px;
            }
        }
    }
}