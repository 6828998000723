.clientlogos-block{
    padding-top: 2rem;
    padding-bottom: 2rem;
    &__text{
        font-weight: bold;
        text-align: center;
    }
    &__carousel{
        &__cell{
            width: 12%;
            height: 70px;
            margin-right: 5px;
            background:{
                size:contain;
                repeat:no-repeat;
                position:center center;
            }
        }
    }
}