.hide-breadcrumbs{
    .pageheader-block__breadcrumb{
        display: none !important;
    }
}

.add-bottom-space{
    .heading_block{
        padding-bottom: 2rem;
    }
}

.eventheader-block{
    a{
        text-decoration: none;
    }
    p{
        text-align: justify;
    }
}