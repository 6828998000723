.columns-block{
    padding-top: 1rem;
    padding-bottom: 3rem;
    h2{
        color:$primary;
    }
    h6{
        font-size: 1rem;
        a{
            color:black;
            &:hover{
                color:$primary;
            }
        }
    }
    @include for-tablet-landscape-up {
        padding-top: 2rem;
        padding-bottom: 5rem;
        &__heading{
            margin-bottom: 1.5rem;
        }
    }
}