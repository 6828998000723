.package-block{
    &__nav{

        border-radius: 0;
        .nav-link {
            border-radius: 0;
            color:$primary;
            font-weight: 500;
            &.is-disabled{
                text-decoration: line-through;
                color:rgba($gray, 0.3);
                &:after{
                    content:" (Sold out)";
                    display: inline-block;
                    margin-left: 0.25rem;
                }
                &:hover{
                    border-color:transparent;
                    color:rgba($gray, 0.3);
                }
            }
            &:hover{
                border-color:transparent;
                color:black;
            }
            &.active{
                color: white;
                font-weight:600;
                background-color: $primary;
                border-color: $primary;
                &.is-disabled{
                    &:hover{
                        color: white;
                    }
                }
            }
        }
    }
    &__content{
        background-color:$primary;
        .tab-pane{
            display: none;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 1rem;
            padding-bottom: 1rem;
            &.active{
                display: flex;
            }
        }
        &__details{
            position: relative;
            text-align: center;
            border-bottom:1px solid rgba(white, 0.5);
            padding:2rem 1rem;
            &:nth-last-child(2){
                border-bottom:none;
            }
            i{
                display: inline-block;
                margin-bottom: 0.75rem;
            }
            h4{
                color:white;
                margin-bottom: 1rem;
            }
            a{
                &.btn-primary-inverted{
                    margin-top: 0.75rem;
                }
                &.btn{
                    display: block;
                    max-width: 240px;
                    margin-left: auto;
                    margin-right: auto;
                }
                &.disabled{
                    color:white;
                    span{
                        color:white
                    }
                    &:hover{
                        cursor: not-allowed;
                    }
                }
            }
            span{
                color:white;
                font-style: italic;
                display: block;
                margin-top: 0.25rem;
            }
        }
        @include for-small-tablet-up {
            .tab-pane{
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
            &__details{
                border-bottom:none;
                padding:1rem 1rem;
                &:nth-child(1){
                    border-bottom:none;
                    border-right:1px solid rgba(white, 0.5);
                }
                &:nth-child(3){
                    border-bottom:none;
                    border-right:none;
                }

                &.one-item{
                    border-top:none;
                    border-bottom:none;
                    border-right:none;
                }
                &.three-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.four-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:none;
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.five-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(9){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.six-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(11){
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.seven-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.eight-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.nine-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.ten-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:none;
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.eleven-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.twelve-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:none;
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.thirteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none
                    }
                    &:nth-child(25){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.fourteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(27){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.fifteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(29){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.sixteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(29){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(31){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.seventeen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(29){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(33){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.eighteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(29){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(33){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(35){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.nineteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(29){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(33){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(35){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(37){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.twenty-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(19){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(29){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(33){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(35){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(37){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(39){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                
            }
        }
        @include for-tablet-portrait-up {
            .tab-pane{
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
            &__details{
                padding:2rem 3rem;
            }
            a{
                &.btn-primary-inverted{
                    margin-top: 0.75rem;
                }
    
            }
        }
        @include for-tablet-landscape-up {
            &__details{
                &:nth-child(1){
                    border-bottom:none;
                    border-right:1px solid rgba(white, 0.5);
                }
                &:nth-child(3){
                    border-bottom:none;
                    border-right:none;
                }
                &.one-item{
                    border-top:none;
                    border-bottom:none;
                    border-right:none;
                }
                &.three-items{
                    &:nth-child(1){
                        border-bottom:none;
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-top:none;
                        border-bottom:none;
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.four-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:none;
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(7){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.five-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none
                    }
                    &:nth-child(7){
                        border-bottom:none;
                        border-right:1px solid rgba(white, 0.5);;
                    }
                    &:nth-child(9){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.six-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(11){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.seven-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.eight-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.nine-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(17){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.ten-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-top:none;
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.eleven-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(21){
                        border-bottom:none;
                        border-right:none;
                    }
                }
                &.twelve-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:none;
                    }
                }
                &.thirteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(25){
                        border-right:none;
                        border-bottom:none;
                    }
                }
                &.fourteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(27){
                        border-right:none;
                        border-bottom:none;
                    }
                }
                &.fifteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(27){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(29){
                        border-right:none;
                        border-bottom:none;
                    }
                }
                &.sixteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(29){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-right:none;
                        border-bottom:none;
                    }
                }
                &.seventeen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(29){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(33){
                        border-right:none;
                        border-bottom:none;
                    }
                }
                &.eighteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(29){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(33){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(35){
                        border-right:none;
                        border-bottom:none;
                    }
                }
                &.nineteen-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(29){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(33){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(35){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(37){
                        border-right:none;
                        border-bottom:none;
                    }
                }
                &.twenty-items{
                    &:nth-child(1){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(3){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(5){
                        border-top:none;
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(7){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(9){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(11){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(13){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(15){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(17){
                        border-bottom:1px solid rgba(white, 0.5);
                        border-right:none;
                    }
                    &:nth-child(19){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(21){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(23){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(25){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(27){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(29){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(31){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(33){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(35){
                        border-right:none;
                        border-bottom:1px solid rgba(white, 0.5);
                    }
                    &:nth-child(37){
                        border-right:1px solid rgba(white, 0.5);
                        border-bottom:none;
                    }
                    &:nth-child(39){
                        border-right:none;
                        border-bottom:none;
                    }
                }
            }
        }
    }
}
.package-flex-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}