$primary: #41C3C3;
$gray: rgba(0,0,0,.8);
$lightGray: rgba($gray, 0.1);

// Off canvas mobile menu
$offcanvas-horizontal-width:        300px;
$offcanvas-vertical-height:         30vh;
$offcanvas-transition-duration:     .4s;


$accordion-icon-color:                    white !default;
$accordion-icon-active-color:             white !default;
$accordion-icon-width:                    1.5rem !default;


.color-white{
    color:white;
}